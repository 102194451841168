<template>
  <div>

  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  setup(_, {root, emit}) {
    return {}
  },

}
</script>

<style scoped>

</style>
