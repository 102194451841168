<template>
    <div>
        <v-dialog v-model="isConfirm" persistent max-width="800">
            <v-card>
                <v-card-title class="px-2">
          <span v-if="statusCheckConfirm">
            คอนเฟิร์มออเดอร์
          </span>
                    <span v-else>
            รายละเอียดการสั่งซื้อ
          </span>
                    <v-spacer></v-spacer>
                    <span class="primary--text">
          {{ dataConfirm.order_code }}
            </span>
                </v-card-title>
                <v-divider></v-divider>
                <v-row class="mx-1">
                    <v-col cols='12' md="6">
                        <div>
                            <span class=" font-weight-semibold me-2">ชื่อลูกค้า : </span>
                            <span>{{ dataConfirm.customer_fname }}</span>
                        </div>
                        <div>
                            <span class=" font-weight-semibold me-2">ผู้รับสินค้า : </span>
                            <span>{{ dataConfirm.order_transportation_name }}</span>
                        </div>
                        <div>
                            <span class=" font-weight-semibold me-2">ที่อยู่ : </span>
                            <span>{{ dataConfirm.order_transportation_full_address }}</span>
                        </div>
                        <div>
                            <span class=" font-weight-semibold me-2">เบอร์โทรศัพท์ : </span>
                            <span>{{ dataConfirm.order_transportation_phone }}</span>
                        </div>
                    </v-col>
                    <v-col cols='12' md="6">
                        <div class="text-md-right">
                            <span class=" font-weight-semibold me-2">วันที่สร้างออเดอร์ : </span>
                            <span>{{ dataConfirm.CREATE | sumdatetime }}</span>
                        </div>
                        <div class="text-md-right">
                            <span class=" font-weight-semibold me-2">ผู้ทำรายการ : </span>
                            <span>{{ dataConfirm.user_create }}</span>
                        </div>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-data-table
                        :headers='headers'
                        :items='dataConfirm.order_detail'
                        fixed-header
                        hide-default-footer
                        mobile-breakpoint='0'
                        dense
                        disable-sort
                        no-data-text="ไม่มีรายการสินค้า !"
                >
                    <template #[`item.order_detail_price`]='{ item }'>
                        {{ item.order_detail_price | formatPrice }}
                        <CurrentCurrency/>
                    </template>
                    <template #[`item.order_detail_discount`]='{ item }'>
                        {{ item.order_detail_discount | formatPrice }} %
                    </template>
                    <template #[`item.order_detail_total`]='{ item }'>
                        {{ item.order_detail_total | formatPrice }}
                        <CurrentCurrency/>
                    </template>
                    <template #[`item.order_detail_vat`]='{ item }'>
                        {{ item.order_detail_vat }} %
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-row class="mx-1 mb-1">
                    <v-col cols="12" md="6" class="pe-1">
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ช่องทางจัดส่ง : </span>
                            <span class="primary--text">{{ dataConfirm.transport_name }}</span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ราคาจัดส่ง/กล่อง : </span>
                            <span class="primary--text">
                {{ dataConfirm.order_transportation_price |formatPrice }} <CurrentCurrency/>
                  </span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">จำนวนกล่อง : </span>
                            <span class="primary--text">
                    {{ dataConfirm.order_transportation_amount }} QTY
                  </span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ราคาขนส่งรวม : </span>
                            <span class="primary--text">
                    {{ dataConfirm.order_transportation_total |formatPrice }} <CurrentCurrency/>
                  </span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">วันที่จัดส่ง : </span>
                            <span class="primary--text">
                    {{ dataConfirm.order_transportation_date  |  sumdate }} <CurrentCurrency/>
                  </span>
                        </div>
                    </v-col>
                    <v-divider vertical class="mt-4"></v-divider>
                    <v-col cols="12" md="6" class="ps-1">
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ราคาก่อนหักส่วนลด : </span>
                            <span class="primary--text">{{ dataConfirm.order_before_discount |formatPrice }} <CurrentCurrency/></span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ส่วนลด : </span>
                            <span class="primary--text">{{ dataConfirm.order_discount_value|formatPrice }} <CurrentCurrency/></span>
                        </div>
                        <!--            <div class="d-flex align-center justify-space-between">-->
                        <!--              <span class="font-weight-semibold">ราคารวมหลังหักส่วนลด : </span>-->
                        <!--              <span class="primary&#45;&#45;text">{{ dataConfirm.order_after_discount |formatPrice }} <CurrentCurrency/></span>-->
                        <!--            </div>-->
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ราคาหลังหักส่วนลด : </span>
                            <span class="primary--text">{{ dataConfirm.order_after_discount |formatPrice }} <CurrentCurrency/></span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ภาษี ({{ dataConfirm.order_vat }}%) : </span>
                            <span class="primary--text">{{ dataConfirm.order_vat_value |formatPrice }} <CurrentCurrency/></span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ราคารวมภาษี : </span>
                            <span class="primary--text">{{ dataConfirm.order_total }} <CurrentCurrency/></span>
                        </div>

                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ราคาสุทธิ : </span>
                            <span class="primary--text">{{ dataConfirm.sum_total_transportation |formatPrice }} <CurrentCurrency/></span>
                        </div>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row v-if="dataConfirm.order_payment_status !=`0`" class="mx-1 mb-1">
                    <v-col cols="12" md="6">
                        <span class="font-weight-semibold text-decoration-underline">รูปหลักฐานการชำระเงิน</span>
                        <v-img :src="dataConfirm.order_payment_slip"
                               @click="imageUrl=dataConfirm.order_payment_slip; isShowImage=true"
                               style="cursor: pointer"
                               height="250"
                               max-height="250"
                               class="rounded-lg elevation-4"></v-img>
                    </v-col>
                    <v-col cols="12" md="6">
                        <span class="font-weight-semibold text-decoration-underline">ข้อมูลการชำระเงิน</span>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ชื่อผู้โอน : </span>
                            <span class="primary--text">{{ dataConfirm.order_payment_slip_confirm }} </span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ธนาคาร : </span>
                            <span class="primary--text">{{ dataConfirm.bank_name }}</span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">เลขบัญชีธนาคาร : </span>
                            <span class="primary--text">{{ dataConfirm.bank_number }}</span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">ชื่อเจ้าของบัญชีธนาคาร : </span>
                            <span class="primary--text">{{ dataConfirm.bank_full_name }}</span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">จำนวนเงินที่โอน : </span>
                            <span class="primary--text">{{ dataConfirm.order_payment_total | formatPrice }} <CurrentCurrency/></span>
                        </div>
                        <div class="d-flex align-center justify-space-between">
                            <span class="font-weight-semibold">วันเวลาที่ชำระ : </span>
                            <span class="primary--text">{{  dataConfirm.order_payment_date | sumdatetime
                                }} </span>
                        </div>
                        <div class="d-flex align-center justify-space-between ">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="me-2 mt-16" bottom right @click="isShowConfirm = true"
                                   :loading="loading"
                                   v-if="statusCheckConfirm"
                                   :disabled="loading">คอนเฟิร์ม
                            </v-btn>
                            <v-btn color="error" class="me-2 mt-16" bottom right @click="isCancel = true"
                                   v-if="statusCheckConfirm">
                                ยกเลิกออเดอร์
                            </v-btn>
                            <v-btn color="secondary" outlined class="mt-16" bottom right
                                   @click="$emit('update:isConfirm',false)">
                                ปิด
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-card-actions v-if="dataConfirm.order_payment_status ===`0`">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined bottom right @click="$emit('update:isConfirm',false)">
                        ปิด
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isShowImage" max-width="800" :overlay-opacity="0.9">
            <v-card>
                <v-btn fab color="error" small absolute top right style="top:1%;right: 1%" @click="isShowImage = false">
                    <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
                <v-img :src="imageUrl"></v-img>
            </v-card>
        </v-dialog>
        <CancelOrder v-model="isCancel" :data-update="dataConfirm"
                     @onUpdate="$emit('onConfirm');$emit('update:isConfirm',false)"/>
        <v-dialog v-model='isShowConfirm' persistent max-width='400'>
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-icon color='success' class='me-2'>{{ mdiCheckCircle }}</v-icon>
                    ยืนยันการคอนเฟิร์มออเดอร์
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color='primary' @click='onConfirm' :loading='loading' :disabled='loading'>
                        ยืนยัน
                    </v-btn>
                    <v-btn color='secondary' outlined @click='isShowConfirm = false'>
                        ยกเลิก
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { sumdatetime, formatPrice, sumdate } from "@/services/filters";
import { ref } from "@vue/composition-api";
import { mdiClose, mdiCheckCircle, } from "@mdi/js";
import store from "@/store";
import { api } from "@/services/api"
import CancelOrder from "@/views/manager/order/orderList/CancelOrder";
import CurrentCurrency from "@/components/CurrentCurrency";

export default {
    components: { CurrentCurrency, CancelOrder },
    model: {
        prop: 'isConfirm',
        event: 'update:isConfirm',
    },
    props: {
        isConfirm: {
            type: Boolean,
            default: false,
        },
        dataConfirm: {
            type: Object,
            default: () => {
            },

        },
        statusCheckConfirm: {
            type: Boolean,
            default: false,
        },
    },
    filters: {
        sumdatetime,
        sumdate,
        formatPrice
    },
    setup(props, { root, emit }) {
        const isShowImage = ref(false)
        const isCancel = ref(false)
        const imageUrl = ref('')
        const headers = ref([
            {
                text: '(รหัส) ชื่อสินค้า',
                value: 'product_name',
                width: 200,
            },
            {
                text: 'ราคา',
                value: 'order_detail_price',
                align: 'right',
                width: 100
            },
            {
                text: 'จำนวน',
                value: 'order_detail_amount',
                align: 'right',
                width: 70
            },
            {
                text: 'ส่วนลด',
                value: 'order_detail_discount',
                align: 'right',
                width: 70
            },
            {
                text: 'ภาษี',
                value: 'order_detail_vat',
                align: 'right',
                width: 70
            },
            {
                text: 'ราคารวม',
                value: 'order_detail_total',
                align: 'right',
                width: 100
            },
        ])
        const loading = ref(false)
        const isShowConfirm = ref(false)
        const onConfirm = () => {
            loading.value = true
            api.put({
                path: '/manager/confirmOrder',
                param: props.dataConfirm.order_id
            }).then(res => {
                store.commit('app/ALERT', {
                    message: res.message,
                    color: res.response ? 'success' : 'error',
                })
                loading.value = false
                isShowConfirm.value = false
                emit('update:isConfirm', false)
                emit('onConfirm')
            }).catch(err => {
                loading.value = false
                console.log('error', err)
            })
        }

        return { headers, mdiClose, isShowImage, imageUrl, onConfirm, loading, isCancel, isShowConfirm, mdiCheckCircle }

    },

}
</script>

<style scoped>

</style>
