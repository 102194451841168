<template>
    <div>
        <v-dialog v-model="isShowDetail" persistent max-width="1000">
            <v-card>
                <v-card-title>
                    รายละเอียดการขายสินค้าประจำวันที่ {{ date | sumdate }}
                </v-card-title>
                <v-data-table
                        :headers='headers'
                        :items='dataList'
                        :loading='loading'
                        no-data-text='ไม่มีข้อมูล !'
                        hide-default-footer
                        loading-text='กำลังโหลดข้อมูล...'
                        no-results-text='ไม่มีข้อมูล !'
                        :items-per-page='-1'
                        class='elevation-0'
                >
                    <template #[`item.order_code`]='{ item }'>
                        <v-btn
                                color='primary'
                                text
                                :loading="loadingDetail"
                                class="font-weight-bold"
                                @click='getOrderId(item.order_id)'
                        >
                            {{ item.order_code }}
                        </v-btn>
                    </template>
                    <template #[`item.productList`]='{ item }'>
                        <ul>
                            <li v-for="a in item.productList">
                                {{ a.product_name }} x {{ a.order_detail_amount }} <span class="font-weight-semibold"> ราคา {{ a.order_detail_total
                                }} <CurrentCurrency/> </span>
                            </li>
                        </ul>
                    </template>
                    <template #[`item.order_total`]='{ item }'>
                        <div class="font-weight-bold">
                            {{ item.order_total }}
                            <CurrentCurrency/>
                        </div>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <br>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" outlined @click="$emit(`update:isShowDetail`,false)">
                        ปิด
                    </v-btn>
                </v-card-actions>
                <ConfirmOrder v-model="isConfirm" :data-confirm="dataEdit"
                              :statusCheckConfirm="statusCheckConfirm"/>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { ref, watch } from "@vue/composition-api"
import { api } from "@/services/api";
import { sumdate } from "@/services/filters";
import CurrentCurrency from "@/components/CurrentCurrency.vue";
import OrderConfirm from "@/views/manager/order/orderConfirm/OrderConfirm.vue";
import ConfirmOrder from "@/views/manager/order/orderList/ConfirmOrder.vue";

export default {
    components: { ConfirmOrder, OrderConfirm, CurrentCurrency },
    model: {
        prop: 'isShowDetail',
        event: 'update:isShowDetail',
    },
    props: {
        isShowDetail: {
            type: Boolean,
            default: false,
        },
        date: {
            type: String,
            default: '',
        }
    },
    filters: {
        sumdate
    },
    setup(props) {
        const headers = ref([
            {
                text: 'เลขที่ออดเดอร์',
                value: 'order_code',
                width: '100',

            },
            {
                text: 'รายการสินค้า',
                value: 'productList',
                width: '300',
            },
            {
                text: 'ราคารวม',
                value: 'order_total',
                width: '100',
                align: 'right',
            },
        ])
        const dataList = ref([])
        const loading = ref(false)
        const loadingDetail = ref(false)
        const isConfirm = ref(false)
        const dataEdit = ref({})
        const statusCheckConfirm = ref(false)

        watch(() => props.isShowDetail, value => {
            if (value) {
                getProductDetail()
            }
        })

        const getProductDetail = () => {
            loading.value = true
            api.get({
                path: `/orderDaily?dateStart=${ props.date }&currency_id=${ localStorage.getItem('currencyId') }`
            })
                .then(res => {
                    dataList.value = res.data
                })
                .catch(err => {
                    console.log('err', err)
                })
            loading.value = false
        }

        const getOrderId = (id) => {
            loadingDetail.value = true
            api.get({
                path: `/order/${ id }?currency_id=${ localStorage.getItem('currencyId') }`
            })
                .then(res => {
                    dataEdit.value = res.data
                    isConfirm.value = true
                })
                .catch(err => {
                    console.log('err', err)
                })
            loadingDetail.value = false
        }

        return {
            getOrderId,
            loading,
            loadingDetail,
            headers,
            dataList,
            isConfirm,
            dataEdit,
            statusCheckConfirm,

        }
    },
}
</script>