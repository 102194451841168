<template>
    <div>
        <v-skeleton-loader
                v-if="loading"
                type="card"
                tile
        ></v-skeleton-loader>

        <v-card v-else>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <vue-apex-charts
                                id="chart-sales-overview"
                                :type="lineCart.chart.type"
                                :options="lineCart"
                                :series="chartData"
                                class="h-full d-flex align-center"
                        ></vue-apex-charts>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <DetailSellProduct v-model="isShowDetail" :date="dateSelection"/>

    </div>
</template>

<script>
import { formatPrice, currentCurrency } from '@/services/filters'
import { useRouter } from "@core/utils";
import { mdiCurrencyUsd, mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import DetailSellProduct from "@/views/manager/DetailSellProduct.vue";

export default {
    components: {
        DetailSellProduct,
        VueApexCharts,
    },
    props: {
        chartData: {
            type: Array,
            default: [
                { name: '', data: [] },
                { name: '', data: [] },
            ],
        },
        month: {
            type: String,
            default: '',
        },
        year: {
            type: String,
            default: '',
        },
        loading: { type: Boolean, default: true },
    },
    setup(props) {
        const { router } = useRouter()
        const isShowDetail = ref(false)
        const dateSelection = ref('')
        const lineCart = ref({
            chart: {
                events: {
                    click: function (event, chartContext, config) {
                        const date = chartContext.w.globals.labels[config.dataPointIndex].toString().length === 1 ? '0' + chartContext.w.globals.labels[config.dataPointIndex].toString() : chartContext.w.globals.labels[config.dataPointIndex].toString();
                        console.log(date)
                        if (date) {
                            dateSelection.value = props.year + '-' + props.month + '-' + date;
                            isShowDetail.value = true;
                        }
                        // router.push({ name: 'report-product-movement', query: { date: selectDateTime } })
                    },
                },
                height: 400,
                type: 'line',
                fontFamily: 'Mitr',
                labels: {
                    show: true,
                },
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: [],
                    },
                    export: {
                        csv: {
                            filename: undefined,
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString()
                            },
                        },
                        svg: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        },
                    },
                    autoSelected: 'zoom',
                },
            },
            stroke: {
                width: [1, 4],
                curve: 'smooth',
                lineCap: '#212121',
            },
            title: {
                text: 'กราฟเปรียบเทียบยอดขายรายวัน/เดือน',
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                textAnchor: 'middle',
                distributed: false,
                formatter(val) {
                    return formatPrice(val)
                },
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    fontFamily: 'Mitr',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#212121',
                    opacity: 0.9,
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        opacity: 0.45,
                    },
                },
            },
            labels: [
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            yaxis: {
                labels: {
                    show: true,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: true,
                    minHeight: undefined,
                    maxHeight: 120,
                    formatter(value) {
                        return formatPrice(value)
                    },
                    style: {
                        fontSize: '12px',
                        cursor: 'pointer',
                        fontFamily: 'Mitr',
                        fontWeight: 'bold',
                        cssClass: 'apexcharts-xaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                },
                title: {
                    text: `ยอดขาย ${ currentCurrency() }`,
                },
            },
            xaxis: {
                show: true,
                rotateAlways: true,
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: false,
                minHeight: undefined,
                maxHeight: 120,
                labels: {
                    show: true,
                    rotate: -90,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: true,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        cursor: 'pointer',
                        fontSize: '12px',
                        fontFamily: 'Mitr',
                        fontWeight: 400,
                    },
                    offsetX: 0,
                    offsetY: 0,
                },
                title: {
                    text: `วันที่`,
                },
            },
            colors: ['#80DEEA', '#F8BBD0'],
            fill: {
                colors: ['#0097A7', '#F8BBD0'],
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.5,
                    colorStops: [
                        [
                            {
                                offset: 0,
                                color: '#5bc603',
                                opacity: 0.7,
                            },
                            {
                                offset: 90,
                                color: '#5bc603',
                                opacity: 1,
                            },
                        ],
                        [
                            {
                                offset: 100,
                                color: '#F8BBD0',
                                opacity: 1,
                            },
                            {
                                offset: 100,
                                color: '#F8BBD0',
                                opacity: 1,
                            },
                        ],
                    ],
                },
            },
            legend: {
                show: false,
                fontSize: '18px',
                fontFamily: 'Mitr',
                fontWeight: 'bold',
                markers: {
                    width: 14,
                    height: 14,
                    strokeWidth: 0,
                    strokeColor: '#212121',
                    radius: 14,
                    offsetX: -1,
                    offsetY: 0,
                },
            },

        })

        // const handleChartClick = (event, chartContext, config) => {
        //     // console.log(event)
        //     console.log(chartContext)
        // }

        return {
            // handleChartClick,
            lineCart,
            dateSelection,
            isShowDetail,
            icons: {
                mdiDotsVertical,
                mdiCurrencyUsd,
            },
        }
    },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }

      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;

  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }

    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
    }

    &:nth-of-type(3) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.3;
        }
      }
    }
  }
}
</style>
