<template>
	<div>
		<v-skeleton-loader v-if="loading" class="mx-auto" max-height="115" type="card"></v-skeleton-loader>
		<v-card v-else>
			<a href="https://softsteelth.com/" class="white--text" style="position: absolute" target="_blank">โปรแกรมเมทัลชีท</a>
			<div class="d-flex">
				<div>
					<v-card-title class="text-base font-weight-semibold">
						<div class="primary--text text-decoration-none">
							ยอดขายปี {{ year }}
						</div>
					</v-card-title>
					<v-card-text class="d-flex align-center">
						<h3 class="text-xl">
							{{ statistics }}
							<CurrentCurrency />
						</h3>
						<span class="text-xs ms-1" :class="checkChange(change) ? 'success--text' : 'error--text'">
              {{ change }}</span
						>
					</v-card-text>
				</div>
				<v-spacer></v-spacer>
				<div class="illustrator-img">
					<v-img :width="avatarWidth" :src="avatar"></v-img>
				</div>
			</div>
		</v-card>
	</div>
</template>

<script>
import CurrentCurrency from "@/components/CurrentCurrency";

export default {
    components : { CurrentCurrency },
    props : {
        statTitle : { type : String, default : '' },
        statistics : { type : String, default : '' },
        change : { type : String, default : '' },
        chipText : { type : String, default : '' },
        chipColor : { type : String, default : '' },
        avatar : { type : String, default : '' },
        year : { type : String, default : '' },
        avatarWidth : { type : String, default : '' },
        loading : { type : Boolean, default : false },
    },
    setup() {
        const checkChange = value => {
            const firstChar = value.charAt( 0 )
            if ( firstChar === '+' ) {
                return true
            }

            return false
        }

        return { checkChange }
    },
}
</script>

<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: 3%;
  bottom: 10%;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}
</style>
