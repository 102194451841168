<template>
    <div>
        <v-row>
            <!--      <v-col cols="12">
                    <v-btn-toggle v-model="countDay" color="primary" mandatory>
                      <v-btn depressed value="1">
                        วันนี้
                      </v-btn>
                      <v-btn depressed value="2">
                        เดือนนี้
                      </v-btn>
                      <v-btn depressed value="3">
                        ปีนี้
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>-->
            <v-col cols="12" class="d-flex">
                <v-select :items="monthList2"
                          v-model="month"
                          no-data-text="ไม่มีข้อมูล"
                          label="เลือกรายการ" filled
                          @change="getDashboard"
                          dense
                          class="me-2"
                          style="max-width: 300px"
                          item-text="th"
                          item-value="value"
                          hide-details
                />
                <v-select :items="yearList"
                          v-model="yearSelection"
                          no-data-text="ไม่มีข้อมูล"
                          label="เลือกปี" filled
                          @change="getDashboard"
                          dense
                          style="max-width: 300px"
                          item-text="th"
                          item-value="value"
                          hide-details
                />
            </v-col>
            <v-col cols="12" md="6" lg="4" class="align-self-end">
                <SaleDaily
                        :avatar="saleDaily.avatar"
                        :avatar-width="saleDaily.avatarWidth"
                        :statistics="saleDaily.statistics"
                        :stat-title="saleDaily.statTitle"
                        :loading="loadingLine"
                ></SaleDaily>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="align-self-end">
                <MonthDaily
                        :avatar="saleMonthly.avatar"
                        :avatar-width="saleMonthly.avatarWidth"
                        :statistics="saleMonthly.statistics"
                        :month="month"
                        :monthList="monthList2"
                        :stat-title="saleMonthly.statTitle"
                        :loading="loadingLine"
                ></MonthDaily>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="align-self-end">
                <YearDaily
                        :avatar="saleYearly.avatar"
                        :avatar-width="saleYearly.avatarWidth"
                        :year="yearSelection + 543"
                        :statistics="saleYearly.statistics"
                        :stat-title="saleYearly.statTitle"
                        :loading="loadingLine"
                ></YearDaily>
            </v-col>

            <v-col cols="12" md="6">
                <SaleCompare :chart-data="chartDataLine" :loading="loadingLine" :month="month" :year="thisYear"/>
            </v-col>
            <v-col cols="12" md="6">
                <SaleCompareYear :chart-data="chartDataLine2" :loading="loadingLine"/>
            </v-col>
            <v-col cols="12" md="6">
                <SaleCompareRanking :chart-data="chartDataLine3" :label3="label3" v-if="!loadingLine"
                                    :loading="loadingLine"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import SaleDaily from "./SaleDaily";
import MonthDaily from "./MonthDaily.vue";
import YearDaily from "./YearDaily.vue";
import SaleCompare from "./SaleCompare.vue";
import { api } from "@/services/api"
import SaleCompareYear from "@/views/manager/SaleCompareYear";
import SaleCompareRanking from "@/views/manager/SaleCompareRanking";
import monthList from "@/fake-db/monthList.json"

export default {
    props: {},
    components: { SaleCompareYear, SaleDaily, MonthDaily, YearDaily, SaleCompare, SaleCompareRanking },
    setup(_, { root, emit }) {
        const countDay = ref('1')
        const loadingLine = ref(false)
        const monthList2 = ref(monthList.data)
        const month = ref('')
        const thisYear = ref('')
        const yearSelection = ref('')
        const yearList = ref([])


        const saleDaily = ref({
            statTitle: '',
            statistics: '0.00',
            avatar: require('@/assets/images/3d-characters/day.png'),
            avatarWidth: '80',
        })
        const saleMonthly = ref({
            statTitle: '',
            statistics: '0.00',
            avatar: require('@/assets/images/3d-characters/month.png'),
            avatarWidth: '80',
        })
        const saleYearly = ref({
            statTitle: '',
            statistics: '0.00',
            avatar: require('@/assets/images/3d-characters/year.png'),
            avatarWidth: '80',
        })
        const chartDataLine = ref([
            { name: '', data: [], type: 'column' },
            { name: '', data: [], type: 'line' },
        ])
        const chartDataLine2 = ref([
            { name: '', data: [], type: 'column' },
            { name: '', data: [], type: 'line' },
        ])
        const chartDataLine3 = ref([
            { name: 'จำนวน', data: [], type: 'column' },
            { name: '', data: [], type: 'line' },
        ])
        const label3 = ref([])

        onMounted(() => {
            if ((new Date().getMonth() + 1) < 10) {
                month.value = '0' + (new Date().getMonth() + 1)
            } else {
                month.value = (new Date().getMonth() + 1).toString()
            }

            getYears()
        })

        const getDashboard = async () => {
            const year = new Date().getFullYear();
            thisYear.value = year.toString();
            loadingLine.value = true;

            try {
                const res = await api.get({
                    path: '/dashboard',
                    param: `?currency_id=${ localStorage.getItem('currencyId') }&dateStart=${ yearSelection.value }-${ month.value }-01&dateEnd=${ yearSelection.value }-${ month.value }-31`,
                });

                const { data } = res;
                saleDaily.value.statistics = data.daily;
                saleMonthly.value.statistics = data.monthly;
                saleYearly.value.statistics = data.yearly;
                chartDataLine.value[0].data = data.monthDataByDay;
                chartDataLine2.value[0].data = data.yearDataByMonth.currentYear;
                chartDataLine2.value[1].data = data.yearDataByMonth.oldYear;
                chartDataLine2.value[0].name = data.yearList[1];
                chartDataLine2.value[1].name = data.yearList[0];
                chartDataLine3.value[0].data = data.rakingProduct.value;
                label3.value = data.rakingProduct.name;

            } catch (error) {
                console.log('error:', error);
            } finally {
                loadingLine.value = false;
            }
        };

        const getYears = () => {
            let year = new Date().getFullYear()
            let years = []
            for (let i = 0; i < 2; i++) {
                years.push({
                    value: year - i,
                    th: year - i + 543,
                })
            }
            yearSelection.value = years[0].value
            yearList.value = years
            getDashboard()
        }


        return {
            countDay,
            saleDaily,
            saleMonthly,
            saleYearly,
            loadingLine,
            chartDataLine,
            chartDataLine2,
            chartDataLine3,
            label3,
            monthList2,
            thisYear,
            month,
            yearList,
            yearSelection,
            getDashboard,
        }
    },

}
</script>

<style scoped>

</style>
